<script
    lang="ts"
    setup
>
    import type { Language } from '~/ts/types/language'
    import { useLanguageStore } from '~/stores/language'

    const languageStore = useLanguageStore()

    const value = ref<string>(languageStore.language)

    const changeLanguage = (value: Language): void => {
        languageStore.setLanguage(value)

        navigateTo({ params: { language: value } })
    }
</script>

<template>
    <div class="flex items-center justify-end whitespace-nowrap">
        <AppFormFieldSelect
            v-model="value"
            name="language"
            :options="languageStore.languageOptions"
            :dropdown-args="{ fromRight: true, width: 'auto', offsetY: 12 }"
            @update:model-value="changeLanguage"
        >
            <template #field="{ active, toggle }">
                <div
                    class="flex items-center justify-end gap-2 text-[14px] font-medium leading-[130%] cursor-pointer"
                    @click="toggle"
                >
                    {{ languageStore.locale.name }}

                    <AppIconChevronDown
                        size="18"
                        color="#000"
                        :rotate="active"
                    />
                </div>
            </template>
        </AppFormFieldSelect>
    </div>
</template>
